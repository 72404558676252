<template>
  <v-main>
    <div class="fill-height d-flex flex-column align-center justify-center">
      <div class="text-h6">Änderung bestätigt.</div>
      <div class="text-body-1 text-center px-4">Du wirst keine automatisierten E-Mails mehr von uns erhalten.</div>
      <div class="text-body-1 text-center px-4"><router-link to="/home">Klicke hier</router-link>, um zur Home-Seite zu gelangen.</div>
    </div>
  </v-main>
</template>

<script>
export default {
  name: "Unsubscribed"
};
</script>

<style scoped>

</style>