import { render, staticRenderFns } from "./Unsubscribed.vue?vue&type=template&id=1a6226c2&scoped=true&"
import script from "./Unsubscribed.vue?vue&type=script&lang=js&"
export * from "./Unsubscribed.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a6226c2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VMain})
